const axios = require('axios')

import getUri from "./index"
import { EventBus } from '@/utils'

export const getProducts = (jwt) => {
  try {
    return axios.post(getUri('api/products'), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const getProductsPerOrder = (order_id, filters, jwt) => {
  try {
    return axios.post(getUri(`api/products/order/${order_id}`), filters, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const getPageProducts = (page, filters, jwt) => {
  try {
    return axios.post(getUri(`api/products?page=${page}`), filters, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const getProduct = (id, jwt) => {
  try {
    return axios.post(getUri(`api/product/${id}`), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const delProduct = (id, jwt) => {
  try {
    return axios.post(getUri(`api/product/${id}/delete`), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const addProduct = (payload, jwt) => {
  try {
    return axios.put(getUri(`api/product`), payload, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}


export const updateProduct = (payload, jwt) => {
  try {
    return axios.post(getUri(`api/product`), payload, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const upProduct = (id, jwt) => {
  try {
    return axios.post(getUri(`api/product/${id}/up`), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const downProduct = (id, jwt) => {
  try {
    return axios.post(getUri(`api/product/${id}/down`), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}