<template>
  <section class="mt-4">
    <b-tabs>
      <b-tab-item label="Таблица">
        <b-field label="Поиск">
          <b-input placeholder="Поиск"
              type="search"
              icon="search"
              v-model="filters.search_value"
              @input="search"
              icon-clickable>
          </b-input>
        </b-field>
        <div class="box">
            <b-field grouped group-multiline>
              <b-field label="Cостояние">
                <b-select v-model="filters.is_deleted" @input="pageChange()">
                  <option :value="true">Удаленые</option>
                  <option :value="false">Активные</option>
                </b-select>
              </b-field>
            </b-field>
          </div>
        <b-pagination
          :total="total"
          :current="currentPage"
          :simple="false"
          :per-page="filters.limit"
          :paginated="false"
          :pagination-simple="true"
          order="is-right"
          @change="pageChange">
        </b-pagination>
        <div class="table-container">
          <b-table
            :data="data"
            :bordered="true"
            :narrowed="true"
            :hoverable="true"
            default-sort-direction="desc"
            default-sort="date">
            <b-table-column field="id" label="ID" width="40" numeric centered v-slot="props">
              {{ props.row.id }}
            </b-table-column>
            <b-table-column field="name" label="Наименование" centered v-slot="props">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column field="dishes" label="Количество вариантов" centered v-slot="props">
              {{ props.row.dishes.length }}
            </b-table-column>

            <b-table-column field="up" label="Поднять" centered v-slot="props">
              <template v-if="props.row.sort_id > 1">
                <button class="button is-link is-light" v-on:click="UP(props.row.sort_id)">
                  <font-awesome-icon icon="arrow-up" />
                </button>
              </template>
              <template v-else="">
                <strong>
                  -
                </strong>
              </template>
            </b-table-column>

            <b-table-column field="down" label="Опустить" centered v-slot="props">
              <button class="button is-link is-light" v-on:click="DOWN(props.row.sort_id)">
                <font-awesome-icon icon="arrow-down" />
              </button>
            </b-table-column>

            <b-table-column field="action" label="Действие" centered v-slot="props">
              <b-tooltip label="Просмотр">
                <router-link :to="{name: 'a-product', params: {id: props.row.id }}" class="navbar-item">
                  <font-awesome-icon icon="eye" />
                </router-link>
              </b-tooltip>
            </b-table-column>
          </b-table>
        </div>
      </b-tab-item>
      <b-tab-item label="JSON">
        <div class="app-json">
          <vue-json-pretty
            :path="'res'"
            :data="data">
          </vue-json-pretty>
        </div>
      </b-tab-item>
    </b-tabs>
  </section>
</template>
<script>
  import debounce from 'lodash/debounce'
  import { EventBus, limits } from '@/utils'
  import {
    getProducts,
    getPageProducts,
    upProduct,
    downProduct
  } from '@/api/product'
  // import { ToastProgrammatic as Toast } from 'buefy'
  
  import VueJsonPretty from 'vue-json-pretty'
  import 'vue-json-pretty/lib/styles.css'
  
  export default {
    name: "ProductTable",
    components: {
      VueJsonPretty
    },
    data() {
      const data = []
  
      return {
        data: data,
        limits: limits,
        currentPage: 1,
        total: 0,
        filters: {
          order: 'asc',
          limit: 25,
          search_value: null,
          is_deleted: false
        },
      }
    },
    computed: {
      //
    },
    mounted: function() {
      let $this = this
  
      getProducts(this.$store.state.jwt_user)
        .then(response => {
          let data = response.data;
          $this.total = data.total
  
          data.data.forEach((item) => {
            this.data.push(item)
          })
        })
        .catch(
          this.data = []
        )
  
      // EventBus.$on('error', (error) => {
      //   $this.pageChange()
      //   this.$toast.open({
      //     // message: `${this.$i18n.t('form.error')} ${error}`,
      //     message: 'error',
      //     type: 'is-danger',
      //     position: 'is-bottom'
      //   })
      // })
  
      // EventBus.$on('fail', (error) => {
      //   $this.pageChange()
      //   this.$toast.open({
      //     // message: `${this.$i18n.t('form.fail')} ${error}`,
      //     message: 'fail',
      //     type: 'is-danger',
      //     position: 'is-bottom'
      //   })
      // })
  
    },
    beforeDestroy: function(){
      EventBus.$off('error')
      EventBus.$off('fail')
    },
    methods: {
      pageChange(page){
        if(page){
          this.currentPage = page
        }
        getPageProducts(this.currentPage, this.filters, this.$store.state.jwt_user)
          .then(response => {
            let data = response.data;
            
            this.data = []
            this.total = data.total
            data.data.forEach((item) => {
              this.data.push(item)
            })
  
          })
          .catch(
            this.data = []
          )
      },
      search: debounce(function() {

        if (this.filters.search_value == null || this.filters.search_value == ''){
          this.pageChange()
          return
        }

        getPageProducts(1, this.filters, this.$store.state.jwt_user)
          .then(response => {
            let data = response.data;
            
            this.data = []
            this.total = data.total
            data.data.forEach((item) => {
              this.data.push(item)
            })

          })
          .catch(() => {
              this.data = []
            }
          )
      }, 500),

      UP(id){
        upProduct(id, this.$store.state.jwt_user).then(() =>
          { 
              getPageProducts(this.currentPage, this.filters, this.$store.state.jwt_user)
                .then(response => {
                  let data = response.data
                  
                  this.data = []

                  this.total = data.total

                  data.data.forEach((item) => {
                    this.data.push(item)
                  })

                })
                .catch(
                  this.data = []
                )

          }
        )
      },

      DOWN(id){
        downProduct(id, this.$store.state.jwt_user).then(() =>
          { 
              getPageProducts(this.currentPage, this.filters, this.$store.state.jwt_user)
                .then(response => {
                  let data = response.data
                  
                  this.data = []

                  this.total = data.total

                  data.data.forEach((item) => {
                    this.data.push(item)
                  })

                })
                .catch(
                  this.data = []
                )
          }
        )
      }
    }
  }
</script>
<style></style>